import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import Container from '@material-ui/core/Container'
import CustomCountdown from '../components/countdown/customCountdown'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '3rem',
    paddingBottom: '7rem'
  },
  luxBtn: {
    background: '#fe66cb',
    border: 0,
    borderRadius: 0,
    boxShadow: '8px 6px #fe3324',
    color: 'white',
    padding: '0 30px',
    fontWeight: '900',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: `44px`,
    zIndex: `1000`,
    maxWidth: `300px`
  }
}))


const Showtime = () => {

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "showtime/bway-hits.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  let youtubeLink = `https://youtu.be/L20V70IUgVg`

  return (
    <Layout>
      <SEO title="Showtime" description="LUX performing arts provides top training in BROADWAY, ACTING & FILM, and SONGWRITING right here in Jersey City. Some students come to LUX for more confidence and some come to train for professional auditions and performances in NYC." />
      <Img fluid={data.file.childImageSharp.fluid} />
      <Container className={classes.root} maxWidth='lg'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <a href={youtubeLink} target='_blank' rel='noreferrer'>
              <Typography variant="h3" component="h3" align="center" gutterBottom="true">
                Show link
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <CustomCountdown />
          </Grid>
          <Grid container item xs={12} sm={12} lg={6} alignItems="center" justify="center">
            <a href={`https://fundraising.fracturedatlas.org/lux-performing-arts`} target='_blank' rel='noreferrer'>
              <Button variant="contained" color="secondary" className={classes.luxBtn}>
                DONATE
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Showtime