import React from 'react';

import Countdown from 'react-countdown';
import Typography from '@material-ui/core/Typography'

import './customCountdown.scss'

const Completed = () => (
  <a href={`https://youtu.be/L20V70IUgVg`} target='_blank' rel='noreferrer'>
    <Typography variant="h3" component="h3" align="center">Show is live</Typography>
  </a>
)


const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completed />
  }

  return (
    <div className='customCountdownContainer'>
      <div className='dateBoxContainer'>
        <div className='dateBox days'>
          <span className='counter'>{days}</span>
          <span>{days > 1? 'DAYS' : 'DAY'}</span>
        </div>
        <div className='dateBox hours'>
          <span className='counter'>{hours}</span>
          <span>{hours > 1 ? 'HOURS' : 'HOUR'}</span>
        </div>
        <div className='dateBox minutes'>
          <span className='counter'>{minutes}</span>
          <span>{minutes > 1 ? 'MINUTES' : 'MINUTE'}</span>
        </div>
        <div className='dateBox seconds'>
          <span className='counter'>{seconds}</span>
          <span>SECONDS</span>
        </div>
      </div>
    </div>
  )
}


const CustomCountdown = () => {
  return (
    <Countdown 
      date={'2021-05-02T17:30:00'}
      renderer={renderer}
    />
  )
}

export default CustomCountdown;